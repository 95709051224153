.App {
  font-family: sans-serif;
  text-align: center;
}
body {
  background: #0e101c;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  color: white;
  padding: 0 20px 50px;
}

.h1 {
  margin-top: 80px;
  color: white;
  font-size: 25px;
  padding-bottom: 0px;
}

.form {
  max-width: 800px;
  margin: 0 auto;
}

.p {
  color: #bf1650;
  text-align: center;
}

select,
input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 15px;
  font-size: 14px;
}

.label,
section > label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 13px;
  margin-top: 20px;
  color: white;
  font-size: 14px;
  font-weight: 200;
}

input[type="submit"],
.button {
  position: relative;
  background: #ec5990;
  color: white;
  text-transform: uppercase;
  border: none;
  margin-top: 20px;
  padding: 20px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 4px;
  display: block;
  appearance: none;
  border-radius: 4px;
  width: 100%;
  font-weight: lighter;
  transition: 0.3s all;
}

.buttonBlack {
  background: black;
  border: 1px solid white;
}

.App {
  max-width: 600px;
  margin: 0 auto;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.counter {
  font-weight: 400;
  background: white;
  color: black;
  padding: 10px 15px;
  border-radius: 4px;
  position: fixed;
  top: 20px;
  right: 30px;
  z-index: 9999999;
}

button[type="button"] {
  padding: 5px;
  background: #516391;
  color: white;
  letter-spacing: 0px;
  text-transform: none;
  padding: 10px;
  letter-spacing: 1px;
}

input[type="submit"]:hover,
button[type="button"]:hover {
  background: #bf1650;
  color: white;
}

input[type="submit"]:active {
  transition: 0.3s all;
  top: 2px;
}

.preact {
  opacity: 0;
  color: white;
}

.preact:hover {
  opacity: 1;
}

.scroll {
  background: rgb(246,246,246);
  background: linear-gradient(283deg, rgba(246,246,246,1) 1%, rgba(191,22,80,0.8491771708683473) 100%);
  border-radius : 5px;
  height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
  padding: 20px
}